(function () {
  angular
    .module("akitabox.core.services.redirect", [
      "akitabox.constants",
      "akitabox.core.services.env",
      "akitabox.core.services.auth",
      "akitabox.core.services.building",
      "akitabox.core.services.document",
      "akitabox.core.services.identity",
    ])
    .factory("RedirectService", RedirectService);

  /* @ngInject */
  function RedirectService(
    $q,
    $window,
    $state,
    $location,
    $timeout,
    apps,
    AuthService,
    BuildingService,
    DocumentService,
    EnvService,
    IdentityService,
    models
  ) {
    var service = {
      findRedirect: findRedirect,
      getDestination: getDestination,
      setDestination: setDestination,
      redirectTo: redirectTo,
      redirectToAPI: redirectToAPI,
      redirectToMobile: redirectToMobile,
      redirectToDesktop: redirectToDesktop,
      redirectToOrganization: redirectToOrganization,
    };

    var destination;

    var LEGACY_BUILDING_URI = /^\/\w+_{1}\w+\/[^/]+/;
    var LEGACY_BUILDING_LIST_URI = /^(\/[^/]+\/projects$)/;
    var PLAN_VIEW_URI = /(\/plan\/)+/; // anything that matches /plan/
    // This pattern is analogous to: `/buildings/{buildingId}/docs/{docId}/page/{pageNumber}/{pinModelType}s/{selectedPinId}?{queryString}`
    // where every {} enclosed phrase is a capture group used to construct the new PV route. Non-capture
    // groups are used heavily to simplify optional portions of the route that are not covered by capture
    // groups. The pinModelType maps to the capture group that determines whether the selected pin is an
    // Asset or a Model. A final non-capure group toward the end, `(?:[^\?]*)`, is responsible for globbing
    // anything extra that doesn't need capturing at the end of the route up to the query string.
    var LEGACY_FAC_URI =
      /\/buildings\/([a-f0-9]+)\/docs\/([a-f0-9]+)(?:\/page\/)?([0-9]+)?(?:\/(asset|room)s\/([a-f0-9]+))?(?:[^?]*)(\?.*)?/i;
    var MOBILE_URI = /^\/(m$|m\/.*)/;

    var REMOVE_QUERY_STRING_STATES = [
      "app.reporting",
      "app.building.reporting",
    ];

    return service;

    /******************* *
     *   Accessors
     * ****************** */

    function getDestination() {
      return destination;
    }

    function setDestination(_destination) {
      destination = _destination;
    }

    function isLegacyRoute(route) {
      return LEGACY_BUILDING_URI.test(route);
    }

    function isPlanViewRoute(route) {
      return PLAN_VIEW_URI.test(route);
    }

    function isMobileRoute(route) {
      return MOBILE_URI.test(route);
    }

    /**
     * Get the new building uri from legacy uri
     * @param {String} currentRoute Current relative path
     * @return {String} new building uri
     */
    function getBuildingRoute(currentRoute) {
      var legacyUri = currentRoute.match(LEGACY_BUILDING_URI);

      return BuildingService.get({ legacy_uri: legacyUri[0] }).then(function (
        building
      ) {
        if (building.length === 0) {
          return null;
        }
        return currentRoute.replace(
          LEGACY_BUILDING_URI,
          "/" + models.BUILDING.ROUTE_PLURAL + "/" + building[0]._id
        );
      });
    }

    /**
     * Takes the current route and checks if it matches a legacy URI pattern.
     * If a legacy pattern is match it will construct and return an appropriate
     * alternative route, which can be used elsewhere for navigation.
     *
     * @param {String} currentRoute Current relative path
     * @param {Promise<string>} - route to redirect to
     */
    function findRedirect(currentRoute) {
      if (isLegacyRoute(currentRoute)) {
        return getBuildingRoute(currentRoute).then(function (newRoute) {
          if (!newRoute && LEGACY_BUILDING_LIST_URI.test(currentRoute)) {
            newRoute = "/" + models.BUILDING.ROUTE_PLURAL;
          }
          return redirectUrl(currentRoute, newRoute);
        });
      }

      if (isPlanViewRoute(currentRoute)) {
        var newRoute = "/plan";
        // parse out the portions of the old fac URI needed to redirect successfully
        // use this instead of an accessor to avoid running the same regex b2b
        var uriParts = LEGACY_FAC_URI.exec(currentRoute);
        var buildingId = getTokenAtIndex(uriParts, 1);
        var docId = getTokenAtIndex(uriParts, 2);
        var page = getTokenAtIndex(uriParts, 3);
        var model = getTokenAtIndex(uriParts, 4);
        var pinId = getTokenAtIndex(uriParts, 5);
        var legacyFilter = getTokenAtIndex(uriParts, 6);

        if (docId) {
          return DocumentService.getById(buildingId, docId)
            .then(buildPVRouteFromFac, handleRedirectError)
            .catch(handleRedirectError);
        }

        return $q.resolve(redirectUrl(currentRoute, newRoute));
      }

      return $q.resolve(redirectUrl(currentRoute));

      /**
       * Handles the conversion between legacy FAC route format and the new PV format.
       *
       * @param {Object} doc - the document data for the :docId in the fac URI
       * @return {String}
       */
      function buildPVRouteFromFac(doc) {
        var query = [];
        var levelId = doc.level;
        if (!levelId) {
          return handleRedirectError(); // Reject & 404 if there is no corresponding floor.
        }

        if (page) {
          query.push("page=" + page);
        }
        if (model && pinId) {
          query.push(model + "=" + pinId);
        }
        // Indicate the presence of legacy filters so the Plan View can display an info dialog about the new filters
        if (!angular.isEmpty(legacyFilter)) {
          query.push("legacyFilter=true");
        }

        var uriParts = [
          models.BUILDING.ROUTE_PLURAL,
          buildingId,
          models.FLOOR.ROUTE_PLURAL,
          levelId,
        ];
        var target = "/" + uriParts.join("/") + "?" + query.join("&");
        newRoute = currentRoute.replace(LEGACY_FAC_URI, target);

        return redirectUrl(currentRoute, newRoute);
      }

      /**
       * Find the string at a given index of URI parts and return it,
       * or return an empty string if not found to avoid bad joins.
       *
       * @param {String[]} tokens - Matching group results from RegExp.exec()
       * @param {Number}   index
       * @return {*}
       */
      function getTokenAtIndex(tokens, index) {
        if (
          tokens &&
          tokens.length >= index &&
          !angular.isUndefined(tokens[index])
        ) {
          return tokens[index];
        }
        return "";
      }

      /**
       * Redirect for when the Legacy FAC URI doesn't contain a documentId
       * with a valid level
       */
      function handleRedirectError() {
        $state.go("root.404");
      }
    }

    /**
     * Redirect the user to the specified route
     *
     * @param {String} route New route
     */
    function redirectTo(route) {
      return $location.url(route).replace();
    }

    function redirectToAPI(route) {
      var baseUrl = EnvService.getBaseUrl();
      var _route = route;

      // Check if user is logged in first before redirecting them to these pages
      if (!AuthService.isAuthenticated()) {
        return $state.go("auth.login", {
          redirect_url: $location.path(),
        });

        // locally we want authn-ui to handle /login, for now until we can
        // get this working in all environments
        // if (EnvService.getEnvName() !== EnvService.LOCAL.ENV) {
        //   return $state.go("auth.login", {
        //     redirect_url: $location.path(),
        //   });
        // } else {
        //   let loginRedirect = "/login";
        //   if ($location.url().length > 1) {
        //     loginRedirect += "?redirect_url=" + $location.url();
        //   }
        //   $window.location.href = loginRedirect;
        //   return;
        // }
      }

      if (EnvService.getEnvName() === EnvService.LOCAL.ENV) {
        baseUrl = baseUrl.replace(
          apps.DESKTOP.ENV.LOCAL.PORT,
          apps.CORE.ENV.LOCAL.PORT
        );
      }

      // don't need to get a current Identity if resetting a password
      var isResetPasswordRoute = /^\/settings\/password.*$/.test(
        $location.path()
      );
      if (isResetPasswordRoute) {
        var resetRoute = baseUrl + _route;
        $window.location.replace(resetRoute);
        return;
      }

      IdentityService.getCurrent().then(function (identity) {
        var isAdminPath = /^\/admin.*$/.test($location.path());
        var isNotSuperUser =
          identity &&
          identity.site_permissions &&
          !identity.site_permissions.is_super_admin;
        var isDenied = isAdminPath && isNotSuperUser;

        if (isDenied) {
          // The page doesn't exist.
          return $state.go("root.404");
        }

        var route = baseUrl + _route;
        $window.location.replace(route);
      });
    }

    /**
     * Redirect the user's browser to the mobile site
     *
     * @param {String} route New route
     */
    function redirectToMobile(route) {
      EnvService.setMobilePref("mobile");

      var subdomain = EnvService.getSubdomain();
      var port = $location.port();

      if (/^\/m($|\/)/.test(route)) {
        route = route.substring(2);
      } else if (!route) {
        route = "";
      }

      var url = EnvService.getBaseUrl("/m" + route, false, subdomain);
      if (port) {
        url = url.replace(/:\d{4,5}/i, ":" + apps.MOBILE.ENV.LOCAL.PORT);
      }

      $window.open(url, "_self");
    }

    /**
     * Redirect the user's browser to the desktop site
     *
     * @param {String} route New route
     */
    function redirectToDesktop(route) {
      EnvService.setMobilePref("desktop");
      var subdomain = EnvService.getSubdomain();

      if (route === "" || route === "/m" || !route) {
        route = "/";
      } else {
        route = route.replace("/m/", "/");
      }

      if (subdomain === "m") {
        subdomain = null;
      }

      $window.open(EnvService.getBaseUrl(route, false, subdomain), "_self");
    }

    /**
     * Redirect the user's browser to an organization. Include the subdomain
     * if they are currently at an organization's subdomain
     *
     * @param {Organization}  organization  Organization to redirect to
     * @param {String}        route         New route
     * @param {Boolean}       newTab        Open in new tab
     */
    function redirectToOrganization(organization, route, newTab) {
      var subdomain = EnvService.getSubdomain();
      var destination = $location.absUrl();
      if (subdomain) {
        destination = destination.replace(
          subdomain,
          organization.subdomain.key
        );
      } else {
        var protocol = $location.protocol();
        destination = destination.replace(
          protocol + "://",
          protocol + "://" + organization.subdomain.key + "."
        );
      }
      if (route) {
        var path = $location.path();
        destination = destination.replace(path, route);
      }
      for (var i = 0; i < REMOVE_QUERY_STRING_STATES.length; ++i) {
        if ($state.is(REMOVE_QUERY_STRING_STATES[i])) {
          destination = destination.split("?")[0];
          break;
        }
      }
      $window.open(destination, newTab ? "_blank" : "_self");
      return $timeout(angular.noop, 3000);
    }

    /**
     * Takes the current route and returns a redirect URL if necessary
     * @param {String} currentRoute Current relative path
     * @returns {String|null} The new route if the currentRoute doesn't exist in the appropriate map, otherwise null
     */
    function redirectUrl(currentRoute, newRoute) {
      var isMobile = isMobileRoute(currentRoute);

      var map = isMobile ? getDesktopToMobile() : getMobileToDesktop();
      var route = newRoute || currentRoute;
      if (/^\/m$/.test(route)) {
        route = "/";
      } else if (isMobile) {
        route = route.replace(/^\/m/, "");
      }
      var currentRegex;

      for (var j = 0; j < map.length; j++) {
        currentRegex = map[j].regex;
        if (currentRegex.test(route)) {
          return map[j].redirect(route, currentRegex);
        }
      }

      return newRoute;
    }

    /**
     * Returns map to transform mobile routes loaded on the desktop
     * @returns {Array}
     */
    function getMobileToDesktop() {
      return [];
    }

    /**
     * Returns map to transform desktop routes loaded on mobile
     * @returns {Array}
     */
    function getDesktopToMobile() {
      return [
        // ORGANIZATION LISTS THAT DO NOT EXIST IN MOBILE
        {
          // /requests --> /m
          // /schedules --> /m
          // /floors --> /m
          // /rooms --> /m
          // /assets --> /m
          // /docs --> /m
          regex: new RegExp(
            "^\\/(" +
              models.SERVICE_REQUEST.ROUTE_PLURAL +
              "|" +
              models.MAINTENANCE_SCHEDULE.ROUTE_PLURAL +
              "|" +
              models.FLOOR.ROUTE_PLURAL +
              "|" +
              models.ROOM.ROUTE_PLURAL +
              "|" +
              models.ASSET.ROUTE_PLURAL +
              "|" +
              models.DOCUMENT.ROUTE_PLURAL +
              "|" +
              "reporting" +
              ").*",
            "i"
          ),
          redirect: function (inUri, regex) {
            return "/m";
          },
        },
        // BUILDING LISTS THAT DO NOT EXIST IN MOBILE
        {
          // /*/*/levels* -> /*/* (building detail)
          // /*/*/docs* -> /*/*
          // /*/*/schedules* -> /*/*
          regex: new RegExp(
            "(^\\/[^/]+\\/[^/]+\\1)\\/(" +
              models.FLOOR.ROUTE_PLURAL +
              "|" +
              models.DOCUMENT.ROUTE_PLURAL +
              "|" +
              models.MAINTENANCE_SCHEDULE.ROUTE_PLURAL +
              ").*",
            "i"
          ),
          redirect: function (inUri, regex) {
            return "/m" + inUri.replace(regex, "$1");
          },
        },
        // BUILDING DETAILS / TABS
        {
          // Just read it
          regex:
            /(^\/[^/]+\/[^/]+)\/(dashboard|overview|info|stats|notes|reporting)/i,
          redirect: function (inUri, regex) {
            return "/m" + inUri.replace(regex, "$1");
          },
        },
        {
          // /*/*/requests/*/* -> /*/*/requests/*
          // /*/*/tasks/*/* -> /*/*/tasks/*
          // /*/*/rooms/*/* -> /*/*/rooms/*
          // /*/*/assets/*/* -> /*/*/assets/*
          regex: new RegExp(
            "(^\\/[^/]+\\/[^/]+\\/(" +
              models.SERVICE_REQUEST.ROUTE_PLURAL +
              "|" +
              models.WORK_ORDER.ROUTE_PLURAL +
              "|" +
              models.ROOM.ROUTE_PLURAL +
              "|" +
              models.ASSET.ROUTE_PLURAL +
              ")\\/[^/]+\\1)\\/[^/]+",
            "i"
          ),
          redirect: function (inUri, regex) {
            return "/m" + inUri.replace(regex, "$1");
          },
        },
        // ORGANIZATION DETAILS
        {
          // /tasks/* --> /tasks
          regex: new RegExp(
            "^\\/" + models.WORK_ORDER.ROUTE_PLURAL + "\\/.*",
            "i"
          ),
          redirect: function (inUri, regex) {
            return "/m/tasks";
          },
        },
        {
          // /*/* --> /m (except /buildings/:building)
          regex: /\/(?!buildings)[a-z-_]+\/.*/i,
          redirect: function (inUri, regex) {
            return "/m";
          },
        },
      ];
    }
  }
})();
