(function () {
  angular
    .module("akitabox.core.auth.sendValidation")
    .controller("SendValidation", SendValidation);

  /* @ngInject */
  function SendValidation(
    // Third party
    $state,
    $stateParams,
    $window,
    // Services
    AuthService,
    EnvService
  ) {
    var self = this;

    // Local variable to control ui for send first call
    var firstSend = true;

    // Attributes
    self.email = $stateParams.email;
    self.sending = false;
    self.sent = false;

    if (!self.email) {
      // this state should never be reached without an email in the querystring
      $state.go("auth.login");

      // locally we want authn-ui to handle /login, for now until we can
      // get this working in all environments
      // if (EnvService.getEnvName() !== EnvService.LOCAL.ENV) {
      //   $state.go("auth.login");
      // } else {
      //   $window.location.href = "/login";
      // }
    }

    // Functions
    self.sendValidation = sendValidation;

    sendValidation();

    function sendValidation() {
      self.sending = true;
      AuthService.sendValidation(self.email)
        .then(function (data) {
          if (firstSend) {
            firstSend = false;
          } else {
            self.sent = true;
          }
        })
        .catch(function (response) {
          var message = "Sorry, we weren't able to complete your request.";
          // Redirect to login page if the account with the supplied email has already been validated
          // or does not exist (server returns 403 in both cases)
          if (response && response.status === 403) {
            $state.go("auth.login", { email: self.email });

            // locally we want authn-ui to handle /login, for now until we can
            // get this working in all environments
            // if (EnvService.getEnvName() !== EnvService.LOCAL.ENV) {
            //   $state.go("auth.login", { email: self.email });
            // } else {
            //   $window.location.href = "/login?email=" + self.email;
            // }
          } else if (
            response &&
            response.data &&
            response.data.error &&
            response.data.error.message
          ) {
            message = response.data.error.message;
          }

          self.errorMessage = message;
        })
        .finally(function () {
          self.sending = false;
        });
    }
  }
})();
