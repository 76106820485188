import { List, Stack, Button } from "@mui/material";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import {
  DocumentResponse,
  AttachmentLinkEnum,
  AttachmentResponse,
  DefaultApiAttachmentsCreateRequest,
  DefaultApiAttachmentsUpdateRequest,
} from "@akitabox/api-client";

import { api } from "../../api";
import { EmptyItem } from "./EmptyItem";
import { LoadingItem } from "./LoadingItem";
import { FileSection } from "./FileSection";
import { stylesheet } from "../../stylesheet";
import { makeUseServiceCall } from "../../hooks/useServiceCall";
import { useGlobalSnackbar } from "../../consecutive-snackbar/GlobalSnackbar";
import {
  AttachmentDialogTestids,
  SelectedFiles,
  BulkActionData,
  SelectedBulkFiles,
} from "../AttachmentDialog";
import {
  ActiveFilters,
  FilterBar,
  FilterKind,
  allImagesFilter,
} from "./FilterBar";
import { Theme } from "@emotion/react";

export interface FileListSectionProps {
  building: string;
  className?: string;
  onFileSelection: (file: DocumentResponse) => void;
  selectedFiles: SelectedFiles;
  selectedBulkFiles: SelectedBulkFiles;
  entityIds?: string[];
  entityType: AttachmentLinkEnum;
  imagesOnly: boolean;
  onResetSelections: () => void;
  onBulkFileSelection: (
    fileId: DocumentResponse["_id"],
    payload: BulkActionData
  ) => void;
}

/**
 * Conditionally renders the list of files in a org and building (optional)
 * @param `FileListSectionProps`
 * @returns FunctionComponent
 */
export const FileListSection: FunctionComponent<FileListSectionProps> = ({
  building,
  entityIds,
  entityType,
  className,
  selectedFiles,
  onFileSelection,
  selectedBulkFiles,
  onResetSelections,
  onBulkFileSelection,
  imagesOnly,
}) => {
  const { simple } = useGlobalSnackbar();
  const [filters, setFilters] = useState<ActiveFilters>({
    [FilterKind.NAME]: "",
    [FilterKind.EXTENSION]: imagesOnly ? allImagesFilter : "",
    [FilterKind.TAG_CATEGORY]: [],
  });

  const hasBuilding = building !== undefined && building !== "";
  const shouldFetchAttachments = entityIds !== undefined;

  const {
    error,
    data: docsResponse,
    isLoading: docsLoading,
  } = makeUseServiceCall(api.documents.getByBuilding, hasBuilding)(
    {
      building,
      archived: false,
      limit: 100,
      name: filters[FilterKind.NAME] || undefined,
      extension: filters[FilterKind.EXTENSION] || undefined,
    },
    {
      params: filters[FilterKind.TAG_CATEGORY].reduce(
        (obj, filter) => ({
          ...obj,
          [`tags_${filter.tagCategory.cat_name}`]:
            filter.tagCategoryValue.value_name,
        }),
        {}
      ),
    }
  );

  const { isLoading: attachmentsLoading, data: attachmentsResponse } =
    makeUseServiceCall(
      api.attachments.getByBuilding,
      shouldFetchAttachments && hasBuilding
    )({
      building,
      linksEntityType: entityType,
      linksEntityId: `$in,${entityIds?.join()}`,
    });

  const { isLoading: tagsLoading, data: tagsResponse } = makeUseServiceCall(
    api.tags.getByBuilding,
    hasBuilding
  )({ building });

  useEffect(() => {
    if (!error) return;

    let message = "Unknown network error";
    // making sure error message is not ""
    if (error.message) {
      message = error.message;
    }

    simple(message, {
      severity: "error",
      testId: AttachmentDialogTestids.FileListError,
    });
  }, [error, simple]);

  const isFileAttachedTo = useCallback(
    (attachments: AttachmentResponse[]) => {
      const fallback = { some: false, every: false };

      if (!entityIds || entityIds.length === 0) {
        return fallback;
      }
      if (attachments.length === 0) {
        return fallback;
      }

      return {
        some: attachments.length >= 1,
        every: attachments.length === entityIds.length,
      };
    },
    [entityIds]
  );

  const getAssociatedAttachments = useCallback(
    (fileId: DocumentResponse["_id"]) => {
      if (!attachmentsResponse || attachmentsResponse.data.length === 0) {
        return [];
      }

      return attachmentsResponse.data.filter(
        (attachment) => fileId === attachment.document._id
      );
    },
    [attachmentsResponse]
  );

  const files = useMemo(() => {
    if (!docsResponse || docsResponse.data.length === 0) {
      // no docs at all so, return nothing
      return;
    }
    return docsResponse.data;
  }, [docsResponse]);

  let content: ReactNode = <EmptyItem css={ss.centered} />;

  if (docsLoading || attachmentsLoading || tagsLoading) {
    content = <LoadingItem css={ss.centered} />;
  } else if (files && files.length > 0) {
    content = files.map((file) => {
      const isFileSelected = selectedFiles[file._id]
        ? Boolean(selectedFiles[file._id])
        : undefined;
      const bulkSelectedAction = selectedBulkFiles[file._id]
        ? selectedBulkFiles[file._id].action
        : undefined;

      const associatedAttachments = getAssociatedAttachments(file._id);
      const { some, every } = isFileAttachedTo(associatedAttachments);

      return (
        <FileSection
          file={file}
          key={file._id}
          attachedToSome={some}
          attachedToEvery={every}
          selected={isFileSelected}
          bulkAction={bulkSelectedAction}
          onFileSelection={onFileSelection}
          onBulkSelection={(action) => {
            switch (action) {
              case "detach": {
                const toBeDetached: DefaultApiAttachmentsUpdateRequest[] = [];
                associatedAttachments.forEach(
                  ({ _id: attachmentId, links }) => {
                    links.forEach(({ entity_id }) => {
                      toBeDetached.push({
                        attachmentId,
                        buildingId: building,
                        attachment: { entity_id, action: "removeLink" },
                      });
                    });
                  }
                );
                onBulkFileSelection(file._id, {
                  action,
                  data: toBeDetached,
                  fileName: `${file.name}${file.extension}`,
                });
                break;
              }
              case "attach": {
                const toBeAttached: DefaultApiAttachmentsCreateRequest[] = [];
                entityIds?.forEach((entity) => {
                  associatedAttachments.forEach(({ links, document }) => {
                    links.forEach(({ entity_id }) => {
                      if (entity !== entity_id) {
                        toBeAttached.push({
                          building,
                          attachment: {
                            document: document._id,
                            links: [
                              {
                                entity_id: entity,
                                entity_type: entityType,
                              },
                            ],
                          },
                        });
                      }
                    });
                  });
                });
                onBulkFileSelection(file._id, {
                  action,
                  data: toBeAttached,
                  fileName: `${file.name}${file.extension}`,
                });
                break;
              }
              case "clear": {
                onBulkFileSelection(file._id, { action });
                break;
              }
              default: {
                throw new Error(`Invalid "${action}" action`);
              }
            }
          }}
        />
      );
    });
  }

  const handleFilterChange = (filters: ActiveFilters) => {
    setFilters(filters);
  };

  return (
    <div css={ss.fileListWrapper} className={className}>
      <FilterBar
        onFilterChange={handleFilterChange}
        activeFilters={filters}
        tagCategories={tagsResponse?.data ?? []}
      ></FilterBar>
      <Stack direction="row" paddingTop={(t: Theme) => t.spacing(1)}>
        <Button
          size="small"
          color="primary"
          disableElevation
          variant="contained"
          onClick={onResetSelections}
          disabled={
            Object.keys(selectedFiles).length === 0 &&
            Object.keys(selectedBulkFiles).length === 0
          }
        >
          RESET SELECTIONS
        </Button>
      </Stack>
      <List>{content}</List>
    </div>
  );
};

const ss = stylesheet({
  centered: { justifyContent: "center" },
  fileListWrapper: {
    width: "100%",
    overflowY: "auto",
  },
});
