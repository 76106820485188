import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  FunctionComponent,
} from "react";

import {
  Box,
  Button,
  Chip,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import SelectViewButton from "./SelectViewButton";
import EditViewsDialog from "./EditViewsDialog";
import { StoredView, FilterTerms } from "./types";
import { Info } from "@mui/icons-material";
import { api } from "../api";
import { makeUseServiceCall } from "../hooks/useServiceCall";

export interface ActiveViewToolbarProps {
  organizationId: string;
  columns: any;
  columnVisibilityModel: any;
  initialColumnVisibilityModel: any;
  filterModel: any;
  visibleFilterTerms: FilterTerms[];
  isFilterChanged: boolean;
  setFilterModel: (filterModel: any) => void;
  setColumnVisibilityModel: (columnVisibilityModel: any) => void;
  setOpenToast: (open: boolean) => void;
  setToastMessage: (message: string) => void;
  showDecommissioned: boolean;
}

const ActiveViewToolbar: FunctionComponent<ActiveViewToolbarProps> = ({
  organizationId,
  columns,
  columnVisibilityModel,
  initialColumnVisibilityModel,
  filterModel,
  visibleFilterTerms,
  isFilterChanged,
  setFilterModel,
  setColumnVisibilityModel,
  setOpenToast,
  setToastMessage,
  showDecommissioned,
}) => {
  const [storedViews, setStoredViews] = useState(() => {
    const storedViews: StoredView[] = JSON.parse(
      localStorage.getItem("storedViews") || "[]"
    );
    return storedViews;
  });
  const [activeView, setActiveView] = useState<boolean>(false);
  const [newViewSaved, setNewViewSaved] = useState(false);
  const [selectedView, setSelectedView] = useState<string>("");
  const [isEditViewsDialogOpen, setIsEditViewsDialogOpen] =
    useState<boolean>(false);

  const { data: fieldGroupResponse } = makeUseServiceCall(
    api.fieldGroups.getByOrganization
  )({
    organizationId,
    limit: 1000,
  });

  const fieldGroups = useMemo(() => {
    return fieldGroupResponse?.data || [];
  }, [fieldGroupResponse]);

  const selectedViewName = useMemo(() => {
    if (!selectedView) {
      return "";
    }
    const view = storedViews.find((view: any) => view.id === selectedView);
    return view ? view.name : "";
  }, [selectedView, storedViews]);
  const hasHiddenColumns = useMemo(() => {
    const hasHidden = Object.values(columnVisibilityModel).some(
      (isVisible) => !isVisible
    );
    return hasHidden;
  }, [columnVisibilityModel]);
  const visibleColumns = useMemo(() => {
    const updatedVisibility = {
      ...initialColumnVisibilityModel,
      ...columnVisibilityModel,
    };

    const columns = Object.keys(updatedVisibility)
      .filter((key) => updatedVisibility[key])
      .filter((column) => !column.startsWith("Actions"))
      .map((column) => column.replace("values.", ""))
      .map(
        (column) =>
          column.charAt(0).toUpperCase() + column.slice(1).toLowerCase()
      );

    return columns;
  }, [columnVisibilityModel, initialColumnVisibilityModel]);

  const activeFilterModel = useMemo(() => {
    return filterModel?.items?.map((item: any) => {
      const match = item.field.match(/^values\.(.+)/);
      const field = match ? match[1] : item.field;
      return field;
    });
  }, [filterModel]);

  const handleSaveView = async () => {
    const id = Math.random().toString(36);
    const view: StoredView = {
      id,
      columns,
      columnVisibilityModel,
      name: `View ${storedViews?.length + 1}`,
      filterModel,
      visibleFilterTerms,
    };
    const updatedViews = [...storedViews, view];
    setStoredViews(updatedViews);
    setNewViewSaved(true);
    setSelectedView(id);
    localStorage.setItem("storedViews", JSON.stringify(updatedViews));
    setToastMessage(`View "${view.name}" Saved`);
    setOpenToast(true);
  };
  const handleUpdateView = () => {
    // Find selected view by id in selectedView
    const selectedViewIndex = storedViews.findIndex(
      (view: { id: string }) => view.id === selectedView
    );
    const updatedViews = storedViews.map((view: any, index: any) => {
      if (index === selectedViewIndex) {
        const updatedView: StoredView = {
          ...view,
          columns,
          columnVisibilityModel,
          filterModel,
          visibleFilterTerms,
        };
        return updatedView;
      }
      return view;
    });
    setStoredViews(updatedViews);
    localStorage.setItem("storedViews", JSON.stringify(updatedViews));
    setToastMessage("View Updated");
    setOpenToast(true);
  };
  const handleApplyView = useCallback(
    (view: StoredView) => {
      if (view) {
        setColumnVisibilityModel(view.columnVisibilityModel);
        setFilterModel(view.filterModel);
        setSelectedView(view.id);
        setActiveView(true);
      }
    },
    [setColumnVisibilityModel, setFilterModel]
  );
  const handleOpenEditViewsDialog = () => {
    setIsEditViewsDialogOpen(true);
  };
  const handleCloseEditViewsDialog = () => {
    setIsEditViewsDialogOpen(false);
  };
  const handleSaveEditedViews = (editedViews: StoredView[]) => {
    setStoredViews(editedViews);
    localStorage.setItem("storedViews", JSON.stringify(editedViews));
  };
  const handleDeleteVisibleFilterTerm = (termToDelete: FilterTerms) => () => {
    // remove the term from the visibleFilterTerms and the filters
    // newFilters will be the filters without the termToDelete.filterItem
    const newFiltersModelItems = [...filterModel.items].filter((item) => {
      const match = item.field.match(/^values\.(.+)/);
      const field = match ? `values.${match[1]}` : item.field;
      return field !== termToDelete.filterItem;
    });
    const newFilterModel = { ...filterModel, items: newFiltersModelItems };
    setFilterModel(newFilterModel);
  };

  const handleResetVisibleFilterTerms = useCallback(() => {
    setColumnVisibilityModel(initialColumnVisibilityModel);
    setFilterModel({ items: [] });
    setSelectedView("");
    setActiveView(false);
  }, [
    setActiveView,
    setFilterModel,
    setSelectedView,
    setColumnVisibilityModel,
    initialColumnVisibilityModel,
  ]);

  const handleApplyFieldGroup = (fieldGroup: any) => {
    const mappedFieldNames = fieldGroup.field_names.reduce(
      (acc: any, array_value: string) => {
        return { ...acc, ["values." + array_value]: true };
      },
      {}
    );
    const newColumnVisibilityModel = {
      ...Object.keys(initialColumnVisibilityModel).reduce(
        (acc, key) => ({ ...acc, [key]: false }),
        {}
      ),
      ...{
        name: true,
        building: true,
        "pinType.name": true,
        Details: true,
        Actions: true,
      },
      ...mappedFieldNames,
    };
    setColumnVisibilityModel(newColumnVisibilityModel);
    if (fieldGroup.type_name) {
      const id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
      const newFilterModel = {
        items: [
          {
            field: "pinType",
            operator: "is",
            id: id,
            value: fieldGroup.type_name,
          },
        ],
      };
      setFilterModel(newFilterModel);
    }
  };

  const handleConvertFieldGroup = (fieldGroup: any) => {
    const id = Math.random().toString(36);
    const mappedFieldNames = fieldGroup.field_names.reduce(
      (acc: any, array_value: string) => {
        return { ...acc, ["values." + array_value]: true };
      },
      {}
    );
    const newColumnVisibilityModel = {
      ...Object.keys(initialColumnVisibilityModel).reduce(
        (acc, key) => ({ ...acc, [key]: false }),
        {}
      ),
      ...{
        name: true,
        building: true,
        "pinType.name": true,
        Details: true,
        Actions: true,
      },
      ...mappedFieldNames,
    };
    let newFilterModel = {};
    if (fieldGroup.type_name) {
      const id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
      newFilterModel = {
        items: [
          {
            field: "pinType",
            operator: "is",
            id: id,
            value: fieldGroup.type_name,
          },
        ],
      };
    } else {
      newFilterModel = { items: [] };
    }
    const view: StoredView = {
      id,
      columns,
      columnVisibilityModel: newColumnVisibilityModel,
      name: `Group: ${fieldGroup.name} ${storedViews?.length + 1}`,
      filterModel: newFilterModel,
      visibleFilterTerms,
    };
    const updatedViews = [...storedViews, view];
    setStoredViews(updatedViews);
    setNewViewSaved(true);
    setSelectedView(id);
    localStorage.setItem("storedViews", JSON.stringify(updatedViews));
    setToastMessage(`View "${view.name}" Saved`);
    setOpenToast(true);
  };

  useEffect(() => {
    if (newViewSaved) {
      const view = storedViews[storedViews?.length - 1];
      handleApplyView(view);
      setNewViewSaved(false);
    }
  }, [storedViews, newViewSaved, handleApplyView]);

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ marginBottom: 2, minHeight: 40 }}
      >
        {(selectedView || activeFilterModel) &&
          (activeView ? (
            <Button
              variant="outlined"
              onClick={() => setActiveView(false)}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "50%",
                display: "block",
                textAlign: "left",
                textTransform: "none",
              }}
            >
              {selectedViewName}
            </Button>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  maxWidth: "70%",
                }}
              >
                <Box sx={{ mt: 1, color: "text.secondary" }}>
                  {hasHiddenColumns && (
                    <Tooltip title={visibleColumns.join(", ")}>
                      <Chip
                        icon={<Info />}
                        label={`${visibleColumns?.length} columns`}
                      />
                    </Tooltip>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    mt: 1,
                    color: "text.secondary",
                    flexWrap: "nowrap",
                    overflow: "auto",
                    scrollbarWidth: "none",
                  }}
                >
                  {visibleFilterTerms.map((term, index) => (
                    <Chip
                      key={index}
                      label={term.description}
                      onDelete={handleDeleteVisibleFilterTerm(term)}
                    />
                  ))}
                </Box>
              </Box>
            </>
          ))}
        {showDecommissioned && (
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Showing Decommissioned Assets Only
          </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {selectedView && !activeView && (
          <Link
            onClick={() => setActiveView(true)}
            sx={{
              whiteSpace: "nowrap", // Prevent text from wrapping to the next line
            }}
          >
            show view name
          </Link>
        )}
        {isFilterChanged && (
          <>
            <Button
              size="small"
              onClick={handleResetVisibleFilterTerms}
              color="secondary"
              sx={{ height: "40px", whiteSpace: "nowrap" }}
            >
              Reset View
            </Button>

            <Button
              onClick={activeView ? handleUpdateView : handleSaveView}
              color="primary"
              sx={{ height: "40px", whiteSpace: "nowrap" }}
              size="small"
            >
              {activeView ? "Update View" : "Save View"}
            </Button>
          </>
        )}
        <SelectViewButton
          storedViews={storedViews}
          fieldGroups={fieldGroups}
          handleApplyView={handleApplyView}
          handleApplyGroup={handleApplyFieldGroup}
          handleConvertGroup={handleConvertFieldGroup}
          handleOpenEditViewsDialog={handleOpenEditViewsDialog}
          handleResetVisibleFilterTerms={handleResetVisibleFilterTerms}
        />
      </Stack>
      <EditViewsDialog
        open={isEditViewsDialogOpen}
        views={storedViews}
        onClose={handleCloseEditViewsDialog}
        onSaveViews={handleSaveEditedViews}
      />
    </>
  );
};

export default ActiveViewToolbar;
