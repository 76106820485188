import { FunctionComponent, useEffect, useState } from "react";
import { InsightsQueryData } from "./InsightsWidgetSelectionDialog";
import { ExecuteQueryByWidgetId, WidgetModel } from "@sisense/sdk-ui";
import { GetWidgetModel } from "./GetWidgetModel";
import { Filter } from "@sisense/sdk-data";
import { QueryWidgetSelection } from "./types";

export interface ExecuteMultiQueryByWidgetIdsProps {
  queryWidgetSelections: QueryWidgetSelection[];
  dashboardOid: string;
  selectedDashboard: string;
  externalFilters: Filter[];
  confirmSelections: (queryData?: InsightsQueryData[]) => void;
}

export const ExecuteMultiQueryByWidgetIds: FunctionComponent<
  ExecuteMultiQueryByWidgetIdsProps
> = ({
  queryWidgetSelections,
  dashboardOid,
  externalFilters,
  confirmSelections,
}) => {
  const [queryData, setQueryData] = useState<InsightsQueryData[]>([]);
  const [widgetModels, setWidgetModels] = useState<Map<string, any>>(new Map());
  const [widgetExecutedStates, setWidgetExecutedStates] = useState(
    new Map(
      queryWidgetSelections.map(
        (queryWidgetSelection: QueryWidgetSelection) => [
          queryWidgetSelection.widgetOid,
          false,
        ]
      )
    )
  );

  useEffect(() => {
    if (!queryWidgetSelections.length) {
      confirmSelections();
    } else if (queryData.length === queryWidgetSelections.length) {
      confirmSelections(queryData);
    }
  }, [confirmSelections, queryData, queryWidgetSelections.length]);

  const handleWidgetModel = (
    widgetOid: string,
    widgetModel: WidgetModel | Error | undefined
  ) => {
    setWidgetModels(new Map(widgetModels.set(widgetOid, widgetModel)));
  };
  return (
    <div>
      {queryWidgetSelections.map((w, index) => {
        if (!widgetModels.get(w.widgetOid)) {
          return (
            <GetWidgetModel
              key={index}
              dashboardOid={dashboardOid}
              widgetOid={w.widgetOid}
              exitComponent={function (widgetModel) {
                handleWidgetModel(w.widgetOid, widgetModel);
              }}
            />
          );
        }
      })}
      {widgetModels.size === queryWidgetSelections.length &&
        queryWidgetSelections.map((w, index) => {
          if (!widgetExecutedStates.get(w.widgetOid)) {
            return (
              <ExecuteQueryByWidgetId
                key={index}
                widgetOid={w.widgetOid}
                dashboardOid={dashboardOid}
                includeDashboardFilters={true}
                filtersMergeStrategy={
                  externalFilters.length ? "codeFirst" : undefined
                }
                filters={externalFilters.length ? externalFilters : undefined}
              >
                {({ data, error }) => {
                  if (data) {
                    const widgetModel = widgetModels.get(w.widgetOid);
                    let dataFormat = [];
                    let color = null;
                    let symbol = null;
                    let decimalFormat = "auto";
                    if (!widgetModel) return null;
                    if (widgetModel.widgetType === "indicator") {
                      const numberFormatConfig =
                        widgetModel?.dataOptions?.value[0]?.numberFormatConfig;
                      color = widgetModel?.dataOptions?.value[0]?.color?.color;
                      if (numberFormatConfig) {
                        symbol = numberFormatConfig.symbol
                          ? numberFormatConfig.symbol
                          : numberFormatConfig.name === "Percent"
                          ? "%"
                          : null;
                        decimalFormat = numberFormatConfig.decimalScale;
                      }

                      dataFormat.push({
                        decimalFormat: decimalFormat,
                        symbol: symbol,
                        color: color,
                      });
                    } else if (
                      widgetModel.widgetType === "table" ||
                      widgetModel.widgetType === "tablewidget"
                    ) {
                      dataFormat = widgetModel.dataOptions.columns.map(
                        (column: any) => {
                          const numberFormatConfig = column.numberFormatConfig;
                          const columnColor = column.color?.color;
                          const columnSymbol = numberFormatConfig?.symbol
                            ? numberFormatConfig.symbol
                            : numberFormatConfig?.name === "Percent"
                            ? "%"
                            : null;
                          const columnDecimalFormat =
                            numberFormatConfig?.decimalScale || "auto";

                          return {
                            decimalFormat: columnDecimalFormat,
                            symbol: columnSymbol,
                            color: columnColor,
                          };
                        }
                      );
                    } else if (widgetModel.widgetType === "pivot2") {
                      const consolidateFormat = (items: any[]) => {
                        return items.map((item: any) => {
                          const numberFormatConfig = item.numberFormatConfig;
                          const itemColor = item.color?.color;
                          const itemSymbol = numberFormatConfig?.symbol
                            ? numberFormatConfig.symbol
                            : numberFormatConfig?.name === "Percent"
                            ? "%"
                            : null;
                          const itemDecimalFormat =
                            numberFormatConfig?.decimalScale || "auto";

                          return {
                            decimalFormat: itemDecimalFormat,
                            symbol: itemSymbol,
                            color: itemColor,
                          };
                        });
                      };

                      const columnsFormat = consolidateFormat(
                        widgetModel.dataOptions.columns || []
                      );
                      const rowsFormat = consolidateFormat(
                        widgetModel.dataOptions.rows || []
                      );
                      const valueFormat = consolidateFormat(
                        widgetModel.dataOptions.values || []
                      );

                      dataFormat = [
                        ...columnsFormat,
                        ...rowsFormat,
                        ...valueFormat,
                      ];
                    }

                    const insightsQueryData: InsightsQueryData = {
                      widgetType: w.widgetType,
                      widgetTitle: w.widgetTitle ? w.widgetTitle : "",
                      dataFormat: dataFormat,
                      widgetId: w.widgetOid,
                      columns: data.columns,
                      rows: data.rows,
                      showTitle: false,
                    };
                    setQueryData([...queryData, insightsQueryData]);
                    setWidgetExecutedStates(
                      widgetExecutedStates.set(w.widgetOid, true)
                    );
                    return null;
                  } else if (error) {
                    const insightsQueryData: InsightsQueryData = {
                      widgetType: w.widgetType,
                      widgetTitle: w.widgetTitle ? w.widgetTitle : "",
                      dataFormat: [
                        { decimalFormat: "", symbol: "", color: "" },
                      ],
                      widgetId: w.widgetOid,
                      columns: [],
                      rows: [],
                      showTitle: false,
                      error: error,
                    };
                    setQueryData([...queryData, insightsQueryData]);
                    setWidgetExecutedStates(
                      widgetExecutedStates.set(w.widgetOid, true)
                    );
                  }
                  return null;
                }}
              </ExecuteQueryByWidgetId>
            );
          }

          return null;
        })}
    </div>
  );
};
