(function () {
  angular.module("akitabox.desktop").run(stateBlock);

  /**
   * @ngInject
   */
  function stateBlock(
    // Angular
    $cookies,
    $location,
    $log,
    $rootScope,
    $timeout,
    $window,
    // Third-party
    $state,
    $mdDialog,
    // Services
    Router,
    AuthService,
    BuildingService,
    EnvService,
    OrganizationService,
    UserService,
    RedirectService,
    ShadowService,
    // Constants
    MOBILE_PREFERENCE_COOKIE,
    RECENT_SUBDOMAIN_COOKIE
  ) {
    Router.configureStates(getStates(), otherwise);

    var deregisterLocationChangeStart = $rootScope.$on(
      "$locationChangeStart",
      onLocationChangeStart
    );
    var deregisterLocationChangeSuccess = $rootScope.$on(
      "$locationChangeSuccess",
      onLocationChangeSuccess
    );
    var deregisterStateChangeStart = $rootScope.$on(
      "$stateChangeStart",
      onStateChangeStart
    );
    var deregisterStateChangeSuccess = $rootScope.$on(
      "$stateChangeSuccess",
      onStateChangeSuccess
    );
    var deregisterStateChangeError = $rootScope.$on(
      "$stateChangeError",
      onStateChangeError
    );

    $rootScope.$on("$destroy", deregisterLocationChangeStart);
    $rootScope.$on("$destroy", deregisterLocationChangeSuccess);
    $rootScope.$on("$destroy", deregisterStateChangeStart);
    $rootScope.$on("$destroy", deregisterStateChangeSuccess);
    $rootScope.$on("$destroy", deregisterStateChangeError);

    function otherwise() {
      var path = $location.path();
      RedirectService.findRedirect($location.url()).then(function (
        redirectRoute
      ) {
        var authenticated = AuthService.isAuthenticated();
        var devicePreference = $cookies.get(MOBILE_PREFERENCE_COOKIE);
        // If user is requesting the index page
        // Authenticated                  = navigate to dashboard
        // Not authenticated (production) = navigate to home.akitabox.com
        // Not authenticated (beta/local) = navigate to login
        if (path === "/") {
          if (authenticated) {
            // If logged in, go to dashboard
            return $state.go("app.dashboard");
          }
        } else if (redirectRoute) {
          // Follow redirect
          return RedirectService.redirectTo(redirectRoute);
        } else {
          // Find url that end with /activity
          var activityPathRegEx = path.match(/(.*)\/activity+$/);

          if (activityPathRegEx && activityPathRegEx.length) {
            // Redirect to overview
            var newUrl =
              $window.location.origin + activityPathRegEx[1] + "/overview";
            $window.location.replace(newUrl);
            return;
          }

          // Otherewise
          var matches = path.match(
            /^\/buildings\/([a-zA-Z0-9_]*)\/(dashboard|overview|info|stats|notes)$/
          );
          if (matches && matches.length) {
            var buildingId = matches[1];
            return $state.go("app.building.detail", { buildingId: buildingId });
          }
          if (/^\/(m$|m\/.*)/.test(path) && devicePreference === "desktop") {
            return $window.location.replace(path.substring(2));
          }
        }
        // If logged in, render 404
        if (authenticated) return $state.go("root.404");
        // Else redirect to login
        return $state.go("auth.login", { redirect_url: $location.url() });

        // locally we want authn-ui to handle /login, for now until we can
        // get this working in all environments
        // if (EnvService.getEnvName() !== EnvService.LOCAL.ENV) {
        //   return $state.go("auth.login", {
        //     redirect_url: $location.url(),
        //   });
        // } else {
        //   let loginRedirect = "/login";
        //   if ($location.url().length > 1) {
        //     loginRedirect += "?redirect_url=" + $location.url();
        //   }
        //   $window.location.href = loginRedirect;
        //   return;
        // }
      });
    }

    function onLocationChangeStart() {
      // Close open dialogs; unless the legacyFilter flag is set to true. It will only be set true
      // (by redirect.service) for the Plan View when redirecting from Legacy FAC routes. It's used
      // to inform plan-view-app.component that it came from a legacy route and that the user must
      // be presented with the LegacyFacFilterDialog to explain why their filters weren't mirrored,
      // and how to use the new PV filters.
      if ($location.search().legacyFilter !== "true") {
        $mdDialog.cancel();
        $mdDialog.cancel(); // FIXME: In case two dialogs have been shown
      }
    }

    function onLocationChangeSuccess($event, newUrl) {
      ShadowService.onLocationChange(newUrl);
      // Set cookie for last used subdomain
      var domain = "." + EnvService.getBaseUrl().split("://")[1].split(":")[0];
      var subdomain = EnvService.getSubdomain();
      if (subdomain) {
        $cookies.put(RECENT_SUBDOMAIN_COOKIE, subdomain, {
          path: "/",
          domain: domain,
        });
      }
    }

    function onStateChangeStart(
      $event,
      toState,
      toParams,
      fromState,
      fromParams,
      options
    ) {
      showProgressBar();

      var outdatedBrowser = EnvService.getBrowser().outdated;

      if (outdatedBrowser) {
        if (toState.name === "root.upgradeBrowser") return;
        $event.preventDefault();
        return $state.go("root.upgradeBrowser");
      }

      if (toState.name.indexOf("app.building") > -1) {
        // If navigating to a building, pan to it on the map
        $rootScope.$broadcast("map:panToBuilding", toParams.buildingId);
      } else if (toState.name === "auth.login") {
        // If authenticated user is requesting login page, navigate to building list
        if (AuthService.isAuthenticated()) {
          if (toParams.redirect_url) {
            $event.preventDefault();
            window.location.href = toParams.redirect_url;
            return;
          }
          $event.preventDefault();
          return $state.go("app.dashboard");
        }
      }

      // Set route destination
      var href = $state.href(toState, toParams);
      RedirectService.setDestination(href);

      if (href && href.match(/^(?!\/media).*\/docs\/[a-zA-Z0-9]+$/)) {
        // ignore media routes
        $location
          .path(href + "/overview")
          .search("")
          .replace();
      }

      // checks if going from child to parent
      if ($state.includes(toState) && !$state.is(toState)) {
        if (angular.equals(toParams, fromParams)) {
          $event.preventDefault();
          $state.reload(toState.name);
        }
      }
    }

    function onStateChangeSuccess($event, toState, toParams) {
      var building;
      var organization;

      $rootScope.showBldgList = false;
      if (toState.name === "app.dashboard") {
        BuildingService.setCurrent(null);
      }
      var titles = [];
      var pageTitle;
      if (toState.data && toState.data.pageTitle) {
        pageTitle = toState.data.pageTitle;
        titles.push(pageTitle);
      }
      titles.push("AkitaBox");
      $rootScope.pageTitle = titles.join(" | ");

      building = BuildingService.getCurrent();
      organization = OrganizationService.getCurrent();
      const user = UserService.getCurrent();

      /**
       * We need to check if the current user is a SRP only user, if they are,
       * show them this page which forces them to navigate to the SRP or pick a
       * different org that they have more access with.
       */
      if (
        user &&
        user.permission_group.is_service_request_portal &&
        toState.name !== "srpRedirect"
      ) {
        return $state.go("srpRedirect");
      }

      // Set Google Tracker page and send page view to Analytics if we're in prod
      var urlPath = $state.href(toState, toParams, { absolute: true });
      ShadowService.setPage(urlPath, pageTitle);
      ShadowService.setOrganization(organization);

      $rootScope.session = {
        organization: organization,
        building: building,
      };

      hideProgressBar();
    }

    function onStateChangeError(
      $event,
      toState,
      toParams,
      fromState,
      fromParams,
      error
    ) {
      $event.preventDefault();
      $log.error(error);
      hideProgressBar();

      // Handle 404 Errors
      if (error && error.status === 404) {
        return $state.go("root.404", null, { location: $location.url() });
      }

      // If not currently on a state, go to the dashboard, unless you don't have a user, then go to login
      if (angular.isEmpty($state.current.name)) {
        var authenticated = AuthService.isAuthenticated();
        if (authenticated) {
          $state.go("app.dashboard");
        } else {
          $state.go("auth.login", {
            redirect_url: $location.url(),
          });

          // locally we want authn-ui to handle /login, for now until we can
          // get this working in all environments
          // if (EnvService.getEnvName() !== EnvService.LOCAL.ENV) {
          //   $state.go("auth.login", {
          //     redirect_url: $location.url(),
          //   });
          // } else {
          //   let loginRedirect = "/login";
          //   if ($location.url().length > 1) {
          //     loginRedirect += "?redirect_url=" + $location.url();
          //   }
          //   $window.location.href = loginRedirect;
          // }
        }
      }
    }

    function showProgressBar() {
      $rootScope.showProgressBar = true;
    }

    function hideProgressBar() {
      $timeout(function () {
        $rootScope.showProgressBar = false;
        $rootScope.$broadcast("abxHideProgressBar");
      }, 300);
    }

    function getStates() {
      return [
        {
          state: "app",
          config: {
            abstract: true,
            url: "",
            templateUrl: "app/desktop/desktop.html",
            resolve: {
              account: getIdentity,
              organization: getOrganization,
              user: getUser,
            },
            controller: "DesktopController",
            controllerAs: "vm",
          },
        },
        {
          state: "app.dashboard",
          config: {
            url: "/buildings",
            templateUrl: "app/desktop/modules/dashboard/dashboard.html",
            controller: "DashboardController",
            controllerAs: "vm",
            data: {
              pageTitle: "Buildings",
            },
          },
        },
        {
          state: "app.qrCode",
          config: {
            url: "/qrcode/:qrCodeId",
            resolve: {
              qrCode: function ($stateParams, QRCodeService) {
                return QRCodeService.getById($stateParams.qrCodeId);
              },
            },
            controller: function ($rootScope, EVENT_QR_CODE_SCANNED, qrCode) {
              $rootScope.$broadcast(EVENT_QR_CODE_SCANNED, { qrCode: qrCode });
            },
            data: {
              pageTitle: "Assign QR Code",
            },
          },
        },
        {
          state: "admin",
          config: {
            url: "/admin",
            abstract: true,
            templateUrl: "app/desktop/modules/admin/admin.html",
            resolve: {
              account: getIdentity,
            },
            controller: "AdminController",
            controllerAs: "vm",
          },
        },
      ];
    }
  }

  /**
   * Get the current user's account
   *
   * @param $state
   * @param $q
   * @param IdentityService
   *
   * @ngInject
   */
  function getIdentity($state, $q, IdentityService) {
    return IdentityService.getCurrent().catch(function (err) {
      // If we catch an error because the server can't be reached, show the 503 page
      if (err.status === -1) {
        $state.go("root.503");
      }
      return $q.reject(err);
    });
  }

  /**
   * Get the user's current organization
   *
   * @param $location
   * @param account               Depends on account
   * @param OrganizationService
   *
   * @ngInject
   */
  function getOrganization($location, account, OrganizationService) {
    var current = OrganizationService.getCurrent();
    if (current) {
      return current;
    }
    return OrganizationService.init($location.path());
  }

  /**
   * Get current user of organization
   *
   * @param organization
   * @param UserService
   *
   * @return {Object} User of organization
   *
   * @ngInject
   */
  function getUser(organization, UserService) {
    if (organization) {
      return UserService.init(organization._id);
    }
    return null;
  }
})();
