(function () {
  angular
    .module("akitabox.core.auth.lockedOut")
    .controller("LockedOutController", LockedOutController);

  /* @ngInject */
  function LockedOutController(
    // Third party
    $state,
    $stateParams,
    $window,
    EnvService
  ) {
    var self = this;

    // Attributes
    self.email = $stateParams.email;

    if (!self.email) {
      // this state should never be reached without an email in the querystring
      $state.go("auth.login");

      // locally we want authn-ui to handle /login, for now until we can
      // get this working in all environments
      // if (EnvService.getEnvName() !== EnvService.LOCAL.ENV) {
      //   $state.go("auth.login");
      // } else {
      //   $window.location.href = "/login";
      // }
    }
  }
})();
